//
// Used by the base.html template to load the main Phisaver frontend
//

import "bootstrap"; // Import bootstrap's javascript
import "bootstrap-icons/font/bootstrap-icons.css"; // Import bootstrap icons
import "./bootstrap-phisaver.scss"; // Import bootstrap css with phisaver theme
import "./sidebar"; // Base PhiSaver (inc. sidebar JS)
import "./phisaver.css"; // Base PhiSaver (inc. sidebar CSS)
import { ColourPalette, Fleet } from "./apiTypes";
import { fetchJSON } from "./utils";

export async function fetchAndApplyPalette() {
    /**
     * Fetch the color palette from the Fleet API and apply it to the page.
     */
    try {
        const fleetName = fleetFromURL(window.location.href);
        if (!fleetName) {
            // Currently only support fleet URLs (not devices)
            return;
        }
        const palette: ColourPalette = await fetchJSON("/api/v1/fleets/" + fleetName + "/colours/");

        // Apply colors dynamically by updating CSS variables
        const root = document.documentElement;
        root.style.setProperty("--bs-primary", palette.primary);
        root.style.setProperty("--bs-secondary", palette.secondary);
        root.style.setProperty("--bs-success", palette.success);
        root.style.setProperty("--bs-danger", palette.danger);
        root.style.setProperty("--bs-warning", palette.warning);
        root.style.setProperty("--bs-info", palette.info);

        // Must directly apply styles to existing buttons - CSS vars are not sufficient (maybe not applied to existing buttons)
        document.querySelectorAll<HTMLButtonElement>(".btn-primary").forEach((btn) => {
            // redundant but good for browsers that don't support CSS variables
            btn.style.backgroundColor = palette.primary;
            btn.style.borderColor = palette.primary;
            btn.style.setProperty("--bs-btn-bg", palette.primary); // Ensure custom variable applies
        });
    } catch (error) {
        console.error("Failed to fetch color palette:", error);
    }
}

async function initialise() {
    console.debug("Initialising Phisaver frontend.");
    await fetchAndApplyPalette();
    //
    // Enable click button to remove record from a django formset
    // Rows are marked for deletion by setting the DELETE field to "True"
    // Hides rows with .card and .form-row classes
    // Handle "remove" button outside or inside the card, or inside a row
    //
    const removeButtons = document.querySelectorAll<HTMLButtonElement>(".remove-record");
    // Add click event listeners to each button
    removeButtons.forEach((button) => {
        button.addEventListener("click", () => {
            // Find the closest row / card to the clicked button
            var row = button.closest<HTMLElement>(".form-row, .card");
            if (!row) {
                // If not found, try to find the closest row to the button
                const closestRow = button.closest<HTMLElement>(".row");
                if (closestRow) {
                    row = closestRow.querySelector<HTMLElement>(".card");
                }
            }

            if (row) {
                // make it invisible (but not removed as it's needed for Django formset)
                row.style.display = "none";
                button.style.display = "none";
                // Find the corresponding DELETE input field within the form
                var deleteInput = row.querySelector<HTMLInputElement>('input[name$="-DELETE"]');
                if (!deleteInput) {
                    // If not found, try to find the DELETE input field within the form
                    const closestForm = button.closest<HTMLFormElement>("form");
                    if (closestForm) {
                        deleteInput = closestForm.querySelector<HTMLInputElement>('input[name$="-DELETE"]');
                    }
                }
                if (deleteInput) {
                    // Set the DELETE field's value to "True" so Django knows it's marked for deletion
                    deleteInput.checked = true;
                }
            }
        });
    });
    console.debug("Initialised Phisaver frontend.");
}

let initialised = false;

document.addEventListener("DOMContentLoaded", () => {
    if (!initialised) {
        initialised = true;
        initialise();
    }
});
/**
 * Extracts the fleet name from the given URL.
 * e.g. https://example.com/fleet/myFleet/ -> myFleet
 * @param href The URL string to extract the fleet name from.
 * @return The fleet name if found, otherwise an empty string.
 */
function fleetFromURL(href: string) {
    const url = new URL(href);
    const pathParts = url.pathname.split("/");
    const fleetIndex = pathParts.indexOf("fleet");
    if (fleetIndex !== -1 && fleetIndex + 1 < pathParts.length) {
        return pathParts[fleetIndex + 1];
    }
    return ""; // Return empty string if fleet is not found
}
