export function initialise() {
    console.debug("Initialising Bootstrap Sidebar");
    const sidebarCollapseButton = document.getElementById("sidebarCollapse") as HTMLButtonElement | null;
    const sidebar = document.getElementById("sidebar") as HTMLElement | null;
    const content = document.getElementById("content") as HTMLElement | null;

    if (sidebarCollapseButton && sidebar && content) {
        sidebarCollapseButton.addEventListener("click", () => {
            sidebar.classList.toggle("active");
            content.classList.toggle("active");
        });
    } else {
        console.error("One or more elements could not be found");
    }

    // Highlight the active link in the sidebar based on the current URL
    const sideMenuLinks = document.querySelectorAll<HTMLAnchorElement>("#sideMenu li a");
    // clear all to start
    sideMenuLinks.forEach((link) => {
        link.parentElement?.classList.remove("active");
    });

    console.debug("Got side menu links", sideMenuLinks);
    sideMenuLinks.forEach((link) => {
        const linkURL = link.getAttribute("href");

        if (linkURL) {
            // Parse to get the path without query strings
            const currentPath = new URL(window.location.href).pathname;
            const linkPath = new URL(linkURL, window.location.origin).pathname;
            if (currentPath === linkPath) {
                link.parentElement?.classList.add("active");
            }
            
        }
    });
}

let initialised: boolean = false;
document.addEventListener("DOMContentLoaded", () => {
    if (!initialised) {
        initialise();
    }
});
