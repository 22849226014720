//
// Used by the base.html template to load the main Phisaver frontend
//
//

import "bootstrap"; // Import bootstrap's javascript
import "bootstrap-icons/font/bootstrap-icons.css"; // Import bootstrap icons
import "./bootstrap-phisaver.scss"; // Import bootstrap css with phisaver theme
import "./phisaver"; // Base PhiSaver (inc. sidebar JS)
import "./phisaver.css"; // Base PhiSaver (inc. sidebar CSS)

async function initialise() {
    console.debug("Initialising Phisaver frontend");

    //
    // Enable click button to remove record from a django formset
    // Rows are marked for deletion by setting the DELETE field to "True"
    // Hides rows with .card and .form-row classes
    // Handle "remove" button outside or inside the card, or inside a row
    //
    const removeButtons = document.querySelectorAll<HTMLButtonElement>(".remove-record");
    // Add click event listeners to each button
    removeButtons.forEach((button) => {
        button.addEventListener("click", () => {
            // Find the closest row / card to the clicked button
            var row = button.closest<HTMLElement>(".form-row, .card");
            if (!row) {
                // If not found, try to find the closest row to the button
                const closestRow = button.closest<HTMLElement>(".row");
                if (closestRow) {
                    row = closestRow.querySelector<HTMLElement>(".card");
                }
            }

            if (row) {
                // make it invisible (but not removed as it's needed for Django formset)
                row.style.display = "none";
                button.style.display = "none";
                // Find the corresponding DELETE input field within the form
                var deleteInput = row.querySelector<HTMLInputElement>('input[name$="-DELETE"]');
                if (!deleteInput) {
                    // If not found, try to find the DELETE input field within the form
                    const closestForm = button.closest<HTMLFormElement>("form");
                    if (closestForm) {
                        deleteInput = closestForm.querySelector<HTMLInputElement>('input[name$="-DELETE"]');
                    }
                }
                if (deleteInput) {
                    // Set the DELETE field's value to "True" so Django knows it's marked for deletion
                    deleteInput.checked = true;
                }
            }
        });
    });
}

let initialised = false;

document.addEventListener("DOMContentLoaded", () => {
    if (!initialised) {
        initialised = true;
        initialise();
    }
});
