export function isEmpty(value: any): boolean {
    // Check for null, undefined, or NaN
    if (value == null || (typeof value === "number" && isNaN(value))) return true;

    // Check for empty string
    if (typeof value === "string" && value.trim() === "") return true;

    // Check for empty array
    if (Array.isArray(value) && value.length === 0) return true;

    // Check for empty object
    if (typeof value === "object" && !Array.isArray(value) && Object.keys(value).length === 0) return true;

    // If none of the above, the value is not empty
    return false;
}

export function strToMs(str: string): number | undefined {
    switch (str) {
        case "Day":
            return 86400000; // 1 day in ms
        case "Week":
            return 86400000 * 7; // 1 week in ms
        case "Year":
            return 86400000 * 365; // 1 year in ms
        default:
            return undefined;
    }
}

export function formatDuration(duration: number): string {
    let days = Math.floor(duration / 86400000);
    let hours = Math.floor((duration % 86400000) / 3600000);
    let minutes = Math.floor((duration % 3600000) / 60000);
    let seconds = Math.floor((duration % 60000) / 1000);
    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
}

export function formatDateISOLocal(date: Date | string, delimiter: string = "-"): string {
    const d = new Date(date);
    let month = (d.getMonth() + 1).toString().padStart(2, "0");
    let day = d.getDate().toString().padStart(2, "0");
    let year = d.getFullYear();
    return [year, month, day].join(delimiter);
}

export function parseDurationString(durationString: string): number {
    const regex = /(?:(\d+)d)?(?:(\d+)h)?(?:(\d+)m\b)?(?:(\d+)mo)?/;
    const matches = durationString.match(regex);

    if (matches) {
        const days = parseInt(matches[1] || "0", 10);
        const hours = parseInt(matches[2] || "0", 10);
        const minutes = parseInt(matches[3] || "0", 10);
        const months = parseInt(matches[4] || "0", 10);

        return days * 86400000 + hours * 3600000 + minutes * 60000 + months * 2628000000;
    }
    return 0;
}

export function formatDurationFromMs(milliseconds: number): string {
    const days = Math.floor(milliseconds / 86400000);
    const hours = Math.floor((milliseconds % 86400000) / 3600000);
    const minutes = Math.floor((milliseconds % 3600000) / 60000);

    let result = "";
    if (days > 0) result += `${days}d `;
    if (hours > 0) result += `${hours}h `;
    if (minutes > 0) result += `${minutes}m`;

    return result.trim() || "0m";
}

export function isLeapYear(date: Date): boolean {
    const year = date.getFullYear();
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

export function printHighchartsChart(chart: any, seriesDetails: boolean = false): string {
    let output = "";
    output += `Chart Type: ${chart.options.chart.type}\n`;
    output += `Chart Title: ${chart.title.textStr}\n`;
    output += `Chart X Axis Title: ${chart.xAxis[0].axisTitle ? chart.xAxis[0].axisTitle.textStr : "None"}\n`;
    output += `Chart Y Axis Title: ${chart.yAxis[0].axisTitle ? chart.yAxis[0].axisTitle.textStr : "None"}\n`;

    if (seriesDetails) {
        output += "\nSeries Properties (excluding data):";
        chart.series.forEach((series: any, index: number) => {
            output += `\nSeries ${index + 1}:\n`;
            output += `  Name: ${series.name}\n`;
            output += `  Type: ${series.type}\n`;
            output += `  Visible: ${series.visible}\n`;
            output += `  Color: ${series.color}\n`;
            output += `  Options:\n`;
            output += `     Stacking: ${series.options.stack}\n`;
            output += `     Opacity: ${series.options.opacity !== undefined ? series.options.opacity : "1.0"}\n`;
            output += `     Show in Legend: ${
                series.options.showInLegend !== undefined ? series.options.showInLegend : "true"
            }\n`;
        });
    } else {
        chart.series.forEach((series: any, index: number) => {
            output += `  Series ${index}: ${series.name} ${series.userOptions["stack"]}\n`;
        });
    }
    return output;
}

export function handleGlobalError(
    event: Event | string,
    source?: string,
    lineno?: number,
    colno?: number,
    error?: Error
) {
    console.error("Caught global error:", event);
    // true means "suppress global error"
    return true;
}
export function handleGlobalUncaughtPromiseRejection(event: PromiseRejectionEvent) {
    console.error("Caught unhandled promise rejection:", event);
}

export async function fetchJSON(url: string): Promise<any> {
    //
    // Fetch JSON from url and return parsed JSON
    // Throw an error on *any* error (network, HTTP, CORS, JSON parsing, etc)
    //
    try {
        console.debug(`Fetching ${location.origin}${url}`);
        const response = await fetch(url);

        if (!response.ok) {
            // Option 1
            //  Code 300,400,500: include response in error
            const responseText = (await response.text()).slice(0, 128);
            console.debug(
                `fetch respoonse error url: ${location.origin}${url} error: ${response.status} ${responseText}`
            );

            throw Error(`${response.status} ${responseText}`);
        }

        // Option 2
        // Code 200
        const jsonData = await response.json();
        return jsonData;
    } catch (error: any) {
        // Option 3
        // 1. Network error, CORS error, etc,
        // 2. Rethrown error from !response.ok above.
        // 3. JSON parsing error
        // Log url here as we don't want to display to user in error message
        console.debug(`fetch error url: ${location.origin}${url} error: ${error}`);
        throw error;
    }
}

export async function fetchJSONElement(url: string, key: string): Promise<any[]> {
    //
    // Fetch data from a URL and return the JSON data in [ref] or an empty array
    // Raise an error if the fetch fails (i.e. not a 200 response)
    // But not if the key doesn't exist
    //
    const jsonData = await fetchJSON(url);

    if (jsonData[key] === undefined) {
        return [];
    } else {
        return jsonData[key];
    }
}
export function deviceFromURL(url: string): string {
    //
    // http://example.com/device/<str:ref>/solar/ -> ref
    //
    const formattedUrl = url.endsWith("/") ? url : url + "/";
    try {
        const parsedUrl = new URL(formattedUrl);
        const pathParts = parsedUrl.pathname.split("/");
        return pathParts[2] ?? "";
    } catch (error) {
        if (error instanceof TypeError) {
            return "";
        }
        throw error;
    }
}

export function getPercentile(values: number[], percentile: number): number | undefined {
    /**
     * Calculate the percentile of a list of values
     */
    values.sort((a, b) => a - b); // Sort the values in ascending order
    const index = (percentile / 100) * (values.length - 1);
    const lower = Math.floor(index);
    const upper = Math.ceil(index);

    if (values[lower] == undefined || values[upper] == undefined) {
        return undefined;
    } else {
        if (lower === upper) {
            return values[lower];
        }
        return values[lower] + (index - lower) * (values[upper] - values[lower]);
    }
}
